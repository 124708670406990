<template>
    <section class="container">
        <Transition  name="fade" appear >
            <div class="row top">
                <div class="col-12 card ">
                    <div class="row m-auto" data-aos="fade-up">
                        <div class="col-md-8 col-12 offset-md-2" >
                            <div v-if="images.length > 0" id="carouselExampleIndicators" class="d-flex carousel slide carousel-fade" data-bs-ride="false" data-bs-touch="true" >
                                <div class="carousel-inner ">
                                    <div class="carousel-item  active">
                                        <div :style="{ 'background-image': 'url(' + $url+'/'+item.featured + ')' }" class="imagebig"  @click="show()" :alt="item.title"></div>
                                    </div>
                                    <div class="carousel-item "  v-for="(image,z) in images" :key="z">
                                        <div :style="{ 'background-image': 'url(' + $url+'/'+image + ')' }" class="imagebig"  @click="show()" :alt="item.title"></div>
                                    </div>
                                </div>
                                <div class="carousel-indicators">
                                    <button @click="selectedIndx = z" type="button" data-bs-target="#carouselExampleIndicators" v-for="(image,z) in images" :data-bs-slide-to="z+1" class=" thumbnail" :class="[z==0 ? 'active' : '']" aria-current="true" :aria-label="'Slide '+z">
                                        <div class="imagebox" 
                                            :style="{ 'background-image': 'url(' + $url+'/'+image + ')' }">
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <img  :src="$url+'/'+item.featured" class="img-fluid" :alt="item.title"/>
                            </div>
                            <p class="pb-0 pt-3 text-uppercase">{{ item.title }}</p>
                            <p  class="text-lowercase">
                                {{ $lang == 'es' ? item.media_es : item.media_en }}<br>
                                {{ item.dimensions }}<br>
                                {{ item.year }}
                            </p>
                        </div>
                    
                    </div>
                
                    <div class="prev d-none d-md-block" >
                        <a class="link" :href="type+indexes[prevSlug]"><prev /></a>
                    </div>
                    <div class="next d-none d-md-block" >
                        <a class="link" :href="type+indexes[nextSlug]"><next /></a>
                    </div>

                    <div class="d-md-none pt-5 justify-content-between d-flex" >
                        <a class="link" :href="type+indexes[prevSlug]"><prev /></a>
                        <a class="link" :href="type+indexes[nextSlug]"><next /></a>
                    </div>
                </div>
            
            </div>
        </Transition>
        <Transition  name="fade" appear >
            <viewer
                ref="viewer"
                :options="{ 
                        inline:false,
                        movable: false,
                        rotatable:false,
                        tooltip:false,
                        toolbar:false,
                        title:false,
                        scalable:false,
                        initialViewIndex: this.selectedIndx}"
                :images="allimages"
                rebuild
                class="viewer"
                @inited="inited">
                <template #default="scope">
                    <img v-for="(src,indx) in scope.images" :key="indx" :src="$url+'/'+src" class="d-none">
                </template>
            </viewer>
        </Transition>
    </section>
</template>
<script>
import axios from 'axios'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import prev from 'vue-material-design-icons/ChevronLeft.vue'
import next from 'vue-material-design-icons/ChevronRight.vue'

export default {
    name: 'ItemComponent',
    components:{prev,next,Viewer},
    
    data(){
        return{
            allimages:[],
            item:{},
            images:[],
            type:'/artwork/',
            indexes: [],
            prevSlug: '',
            nextSlug:'',
            selectedIndx: 0
        }
    },
    created(){
        this.getArtwork()
        if(this.$route.path.includes('/sketch/')){
            this.type = '/sketch/'
        }else{
            this.type = '/artwork/'
        }
        
    },
    methods:{
       
        inited (viewer) {
            this.$viewer = viewer
        },
        show () {
            this.$viewer.show()
        },
        async getArtwork(){
            await axios.get(this.$url+'/artwork/'+this.$route.params.slug,{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.item       = res.data.record                
                this.images     = res.data.extra_images        
                this.indexes    = res.data.indexes        

                this.allimages = this.images
                this.allimages.unshift(this.item.featured)
                
                this.prevSlug = this.prev()
                this.nextSlug = this.next()
            }).catch((err)=>{
                console.log(err)
            })
        },
        prev(){
            if(this.indexes.indexOf(this.item.slug)-1 < 0){
                return this.indexes.length-1
            }
            return this.indexes.indexOf(this.item.slug)-1
        },
        next(){
            if(this.indexes.indexOf(this.item.slug)+1 >= this.indexes.length){
                return 0
            }
            return this.indexes.indexOf(this.item.slug)+1
        }        
    },
    
}
</script>