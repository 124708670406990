<template>
  <div id="app">
    <MenuComponent data-aos="fade-down" data-aos-delay="200"/>
    <router-view/>
   
    <SubscriptionComponent v-if="$route.name == 'home' || 
                                 $route.name == 'artist' || 
                                 $route.name == 'contact' || 
                                 $route.name == 'sketches' || 
                                 $route.name == 'paintings' || 
                                 $route.name == 'events' || 
                                 $route.name == 'shop'
                                  " data-aos="fade-left" data-aos-delay="200"/>
    <div class="row pt-5 ">
        <div class="col-12 " v-show="false">
            <ul class="list-unstyled d-flex justify-content-center a-unstyled">
            <li class="small-text text-uppercase p-2"><router-link to="/privacy-policy">{{ politicas }}</router-link></li>
            <li class="small-text text-uppercase p-2" style="color:#959595;">•</li>
            <li class="small-text text-uppercase p-2"><router-link to="/terms-and-conditions">{{ terminos }}</router-link></li>
            </ul>
        </div>
      <div class="col-12 ">
        <div class="row">
            <div class="col-6 text-start p-3 ps-5"> <h3 class="small-text  pe-3" style="color:#999">@{{year}} MARINA SAMONT</h3></div>
            <div class="col-6  m-auto ">
                <ul class="list-unstyled d-flex justify-content-end">
                    <li class="nav-item">
                        <a href="https://www.instagram.com/marinasamont" class="p-1 icon"><InstagramIcon width="15" alt="Instagram"/></a>
                    </li>
                    <li class="nav-item">
                    <a href="https://www.facebook.com/marinasamont" class="p-1 icon"><FacebookIcon width="15" alt="Facebook"/></a>
                    </li>
                </ul>
            </div>
        </div>
   
      </div>
    </div>
    
  </div>
</template>

<script >
import moment from 'moment';
import MenuComponent from './components/MenuComponent.vue';
import SubscriptionComponent from './components/SubscriptionComponent.vue';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
import InstagramIcon from 'vue-material-design-icons/Instagram.vue'
export default {
    name: 'ArtistComponent',
    components:{MenuComponent,SubscriptionComponent,FacebookIcon,InstagramIcon},
    data(){
        return{
            artist: 'Marina SaMont',
            year: new Date().getFullYear()
        }
    },
   
    computed:{
        politicas(){
            return this.$lang == 'en' ? "Privacy Policy" : "Políticas de Privacidad"
        },
        terminos(){
            return this.$lang == 'en' ? "Terms and Conditions" : "Términos y Condiciones"
        },
        derechos(){
            return this.$lang == 'en' ? "ALL RIGHTS RESERVED" : "TODOS LOS DERECHOS RESERVADOS"
        },
    },
    methods:{
        format(date,format){
            return moment(date).format(format)
        },
        
    }
}
</script>


